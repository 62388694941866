import { useRef } from 'react';
import { useState } from "react";
import react from 'react';


function App() {
    let computerSelection;
    let playerSelection;
    let outcome;
  
    let tracker = useRef();
    let hole = useRef();
    let scoreBoard = useRef("");
    let buttons = useRef();
    let rock = useRef();
    let paper = useRef();
    let scissors = useRef();
    let redo = useRef();
    let result = useRef();
  
    const [score, setScore] = useState(0);
  
  
    function computerTurn() {
      let rng = Math.floor(Math.random() * 3) + 1;
      switch (rng) {
        case 1:
          computerSelection = "ROCK";
          rock.current.classList += " computer_winner";
          break;
        case 2:
          computerSelection = "PAPER";
          paper.current.classList += " computer_winner";
          break;
        case 3:
          computerSelection = "SCISSORS";
          scissors.current.classList += " computer_winner";
          break;
      }
    }
  
    function winner() {
      console.log("Human:" + playerSelection + " Computer:" + computerSelection)
      if (playerSelection == computerSelection) {
        switch (computerSelection) {
          case "ROCK":
            rock.current.classList += " draw";
            paper.current.style.opacity = 0;
            scissors.current.style.opacity = 0;
            break;
          case "PAPER":
            paper.current.classList += " draw";
            rock.current.style.opacity = 0;
            scissors.current.style.opacity = 0;
            break;
          case "SCISSORS":
            scissors.current.classList += " draw";
            paper.current.style.opacity = 0;
            rock.current.style.opacity = 0;
            break;
        }
        return outcome = "DRAW";
      }
      else if (playerSelection == "ROCK" &&  computerSelection == "SCISSORS") {
        setScore(score + 1);
        rock.current.classList += " human_winner";
        paper.current.style.opacity = 0;
        return outcome = "YOU WIN";
      }
      else if (playerSelection == "PAPER" &&  computerSelection == "ROCK") {
        setScore(score + 1);
        paper.current.classList += " human_winner";
        scissors.current.style.opacity = 0;
        return outcome = "YOU WIN";
      }
      else if (playerSelection == "SCISSORS" &&  computerSelection == "PAPER") {
        setScore(score + 1);
        scissors.current.classList += " human_winner";
        rock.current.style.opacity = 0;
        return outcome = "YOU WIN";
      }
      else {
        switch (playerSelection) {
          case "ROCK":
            rock.current.classList += " human_winner";
            scissors.current.style.opacity = 0;
            break;
          case "PAPER":
            paper.current.classList += " human_winner";
            rock.current.style.opacity = 0;
            break;
          case "SCISSORS":
            scissors.current.classList += " human_winner";
            paper.current.style.opacity = 0;
            break;
        }
        return outcome = "YOU LOSE";
      }
    }
  
    function openRules() {
      // add style to app of modal open and create close button for rule set
      if (document.body.className == "modal_open") {
        document.body.className = "";
      } else {
        document.body.className = "modal_open";
      }
    }
  
    function replay() {
      redo.current.style.opacity = 0;
      redo.current.style.pointerEvents = "none";
      paper.current.style.opacity = 1;
      rock.current.style.opacity = 1;
      scissors.current.style.opacity = 1;
      rock.current.classList = "button";
      paper.current.classList = " button";
      scissors.current.classList = "button";
      buttons.current.classList = "buttons";
      result.current.innerText = "";
    }
    
    function onChoice(choice) {
      playerSelection = choice;
      buttons.current.classList += " set";
      setTimeout(() => {
        computerTurn();
        winner();
        scoreBoard.current.innerText = score;
        result.current.innerText = outcome;
        redo.current.style.opacity = 1;
        redo.current.style.pointerEvents = "all";
      }, 2000);
    }
  
    function mouseTracker(e) {
      tracker.current.style.setProperty('--x', e.pageX + 'px');
      tracker.current.style.setProperty('--y', e.pageY + 'px');
      hole.current.style.setProperty('--x', e.pageX + 'px');
      hole.current.style.setProperty('--y', e.pageY + 'px');
    }
  
    return (
      <div className="tracker mouse-cursor-gradient-tracking" ref={tracker} onMouseMove={mouseTracker}>
      <div className="app">
        <div className="scoreboard">
          <h1 className="scoreboard_title">Janken Pon!</h1>
          <div className="scoreboard_score">
            <h1 className="score_title">SCORE:</h1>
            <h1 className="score_score" ref={scoreBoard}>{score}</h1>
          </div>
          <div className="result">
            <h1 className="result_text" ref={result}>
            </h1>
          </div>
        </div>
        <div className="field">
          <div className="buttons" ref={buttons}>
            <div className="button_row">
              <div className="button" ref={rock}>
                <button className="button_action button_action--rock" onClick={() => onChoice("ROCK")}>
                  <img className="button_img" src="rock.svg" alt="" />
                </button>
              </div>
              <div className="button" ref={paper}>
                <button className="button_action button_action--paper" onClick={() => onChoice("PAPER")}>
                  <img className="button_img" src="paper.svg" alt="" />
                </button>
              </div>
            </div>
            <div className="button_row" >
              <div className="button" ref={scissors}>
                <button className="button_action button_action--scissors" onClick={() => onChoice("SCISSORS")}>
                  <img className="button_img" src="scissors.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
          <div className="redo" ref={redo} onClick={replay}>
            <h1 className="redo_title">
              PLAY AGAIN
            </h1>
          </div>
        <div className="rules button_hover" onClick={openRules}>
          <h1 className="rules_title">
            RULES
          </h1>
        </div>
        </div>
      </div>
        <div className="rules_modal">
          <div className="modal_close" onClick={openRules}>
            <img src="close.svg" alt="" />
          </div>
          <div className="modal_content">
            <h1 className="modal_title">The Basic Rules of RPS</h1>
            <p className="modal_para">
              Despite its underlying complexity, the game’s rules are straightforward. Players deliver hand signals representing rock, paper, or scissors, with the outcome determined by these three rules:
            </p>
            <ul>
              <li>Rock wins against scissors.</li>
              <li>Scissors win against paper.</li>
              <li>Paper wins against rock.</li>
            </ul>
          </div>
        </div>
      <div className="porthole" ref={hole}></div>
      </div>
    );  
}

export default App;
